.fifo-feedstock-table {
  .react-table-pagination {
    width: 100% !important;
  }

  .feedstock-table thead tr:first-of-type th:not(:nth-child(1)) {
    border-left: thin solid;
    border-left-color: inherit;
  }

  .feedstock-table th:nth-child(5),
  .feedstock-table th:nth-child(7),
  .feedstock-table th:nth-child(9),
  .feedstock-table th:nth-child(12) {
    border-left: thin solid;
    border-left-color: inherit;
  }
  .feedstock-table td:nth-child(5),
  .feedstock-table td:nth-child(7),
  .feedstock-table td:nth-child(9),
  .feedstock-table td:nth-child(12) {
    border-left: thin solid;
    border-color: inherit;
  }

  .feedstock-table thead tr:first-child th {
    font-weight: bold;
  }

  .status-badge {
    padding: 4px 9.5px;
    border-radius: 3px;
    background-color: #ecfafe;
  }

  .more-details-section {
    box-shadow:
      0px 24px 25px -20px rgba(0, 0, 0, 0.1) inset,
      0px -24px 25px -20px rgba(0, 0, 0, 0.1) inset !important;
  }

  .feedstock-table-aggregated thead tr:first-of-type th:not(:nth-child(1)) {
    border-left: thin solid;
    border-left-color: inherit;
  }

  .feedstock-table-aggregated th:nth-child(3),
  .feedstock-table-aggregated th:nth-child(5),
  .feedstock-table-aggregated th:nth-child(7),
  .feedstock-table-aggregated th:nth-child(9) {
    border-left: thin solid;
    border-left-color: inherit;
  }
  .feedstock-table-aggregated td:nth-child(3),
  .feedstock-table-aggregated td:nth-child(5),
  .feedstock-table-aggregated td:nth-child(7),
  .feedstock-table-aggregated td:nth-child(9) {
    border-left: thin solid;
    border-color: inherit;
  }

  .feedstock-table-aggregated thead tr:first-child th {
    font-weight: bold;
  }
  .sorting-element {
    padding-right: 15px;
  }
  .highlight {
    font-weight: 500;
    text-decoration: underline;
  }
  .cursor-pointer {
    cursor: pointer;
  }

  .cursor-pointer:hover {
    font-weight: 500;
    text-decoration: underline;
  }
}

.outgoing-breakdown-popover {
  .breakdown-header {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #111;
  }

  .breakdown-item-header {
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: 0.25px;
    color: rgba(17, 17, 17, 0.64);
  }

  .breakdown-item-value {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: 0.2px;
    color: #111;
  }
}
.highlight {
  font-weight: 500;
  text-decoration: underline;
}
.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer:hover {
  font-weight: 500;
  text-decoration: underline;
}
