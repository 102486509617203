.saf-document {
  .accordion-body,
  .accordion-button {
    @apply px-0.5;
    display: flex !important;
    align-items: center !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .accordion-body {
    border-bottom: none !important;
    padding-top: 40px !important;
  }

  .accordion-button {
    @apply text-xl items-center;
  }

  .accordion-button svg {
    opacity: inherit !important;
  }

  .view-doc__max-scroll {
    max-height: 100vh;
  }

  .primary-btn {
    background-color: var(--bs-btn-bg) !important;
  }

  .form-manual-entry,
  .view-document {
    .flatpickr-calendar.static {
      position: relative !important;
    }
  }
}
