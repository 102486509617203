.small-text {
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.2px;
  color: rgba(17, 17, 17, 0.57);
}

.compliance-detailed-popover {
  max-width: 400px;
  width: 400px;
  min-width: 400px;
  padding: 0;
  .popover-body {
    opacity: 1;
  }
  .flatpickr-calendar.static {
    position: relative !important;
  }
}

.detailed-view-table tr th {
  @apply bg-transparent text-[#111];
  min-width: 200px !important;
  background-color: #fafafa;
}
.detailed-view-table + .react-table-pagination {
  padding-bottom: 9.5rem;
}

.apply-changes-button {
  background-color: #ededed;
  color: black;
}

.all-transactions-button {
  background-color: #404040;
}
