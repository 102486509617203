@import "@bphxd/ds-core-react/lib/scss/bp-core.scss";

.fullscreen-modal-wrapper {
  .modal {
    top: 78px;

    &-content {
      @apply rounded-0 px-4;
    }
  }
}
