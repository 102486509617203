.mass-balance-table td:nth-child(5) {
  border-left: thin solid;
  border-color: inherit;
}

.mass-balance-table thead tr:nth-child(1) th {
  border-top: none !important;
}

.mass-balance-table thead tr th:nth-child(2) {
  border-left: thin solid;
  border-left-color: inherit;
}

.mass-balance-table tbody tr td:nth-child(2) {
  border-left: thin solid;
  border-left-color: inherit;
}

.mass-balance-table th:nth-child(5) {
  border-left: thin solid;
  border-left-color: inherit;
}

.mass-balance-table thead tr:first-child th {
  font-weight: bold;
  border-top: 1px solid #666666;
}

.mass-balance-table thead tr:nth-child(2) th {
  background-color: rgba(255, 255, 255, 0);
}

.mass-balance-table td.expanded-outbound {
  border-left: thin solid;
  border-left-color: inherit;
}

.mass-balance-table td.expanded-action {
  border-left: thin solid;
  border-left-color: inherit;
  @apply align-middle;
}
