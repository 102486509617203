.dashed-border {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23111111A3' stroke-width='2' stroke-dasharray='9' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 5px;
}

.send-feedback-form {
  z-index: 999;
  box-shadow: 0 8px 6px 4px rgba(0, 0, 0, 0.1);

  .send-feedback-header {
    font-family: Roboto;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.78;
    letter-spacing: 0.15px;
    color: #111;
  }
}

#sendFeedbackButton {
  border-radius: 3px;
  background-color: #3789ad !important;
  color: white !important;

  bottom: 8vh !important;
  z-index: 999;
  position: fixed;
  right: -62px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: rgba(255, 255, 255, 0.92);
}

.success-message {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 0.1px;
  text-align: center;
  color: #111;
}

.offcanvas.offcanvas-end {
  width: 400px !important;
  box-shadow: -5px 0 10px -6px rgba(0, 0, 0, 0.5);
}
