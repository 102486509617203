@import "@bphxd/ds-core-react/lib/scss/bp-core.scss";

$disabled-text: #c3c3c5;
$disabled-btn-dark: #6c6b70;

.credit-gen {
  &-table {
    .credit-gen-status-option:disabled {
      color: $disabled-text;
      cursor: not-allowed;
    }
    .credit-gen-date-picker {
      padding-left: 40px;
      :disabled {
        cursor: not-allowed;
      }
    }

    .credit-gen-prepare-btn {
      background-color: black;
      border-radius: 0px;
      :disabled {
        background-color: $disabled-btn-dark;
      }
    }
  }
}

.submission {
  &__modal-content {
    @apply space-y-5;

    .modal-title {
      @apply text-xl;
      color: var(--light-typography-text-black-light);
    }
  }
  &__form {
    @apply row g-6 px-2;
  }
  &__reviewEmail {
    @apply lg:w-2/4;

    .helloAllAttached {
      padding-left: 8px;
      padding-right: 12px;
      font-size: 18px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.78;
      letter-spacing: 0.2px;
      color: var(--dark-default-typography-text-black-light);
    }
  }
  &__btn-group {
    @apply col-md-12 space-x-3 mt-4;
    .btn {
      @apply rounded-none;
    }
  }
  &__checkbox {
    @apply col-12 pt-6;
  }
}
