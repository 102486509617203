.compliance-datepicker-container {
  [data-cy="flatpickr-datepicker"] {
    position: absolute;
    width: 100%;
  }
  [data-cy="flatpickr-wrapper"] {
    overflow: visible;
  }
}

.compliance-datepicker-remove {
  position: relative;
  float: right;
  margin-top: 8px;
  margin-right: 10px;
  z-index: 10;
  cursor: pointer;
}

.compliance-datepicker {
  z-index: -1;
}

.compliance-datepicker-container {
  position: relative;
}
