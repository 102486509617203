@import "@bphxd/ds-core-react/lib/scss/bp-core.scss";

.status-item {
  align-items: center;
  margin-bottom: 7px;

  @apply flex gap-3;
}

.checklist-icon {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  height: 1rem;
  width: 1rem;
  border: 1px solid transparent;
  border-radius: 50%;
  flex-shrink: 0;

  &.success {
    @apply text-white bg-success;
  }
  &.partial {
    @apply text-yellow-400;
  }
}

.checklist-popover {
  margin: 0 16px 0 7px;
  border-radius: 4px;
  background-color: var(--fills-light-primary);
}

.progress-indicator {
  width: 98px;
  text-align: center;
}
