/* Graph Element  */
.labeless-inventory-graph-element {
  max-width: 146px;
  margin-top: 52px;
}

.inventory-header-line {
  background-color: #e0e0e1;
  width: 100%;
  margin-top: 13px;
  height: 1px;
  justify-content: flex-end;
}

.inventory-border {
  display: flex;
  flex-flow: column;
  align-items: center;
  min-height: 260px;
}

.inventory-graph-set {
  border-style: solid;
  border-width: thin;
  border-radius: 0.25em;
  border-color: #e0e0e0;
  padding: 15px;
}

.inventory-label {
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 16px;
  color: #150f37;
}

.inventory-month-label {
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 16px;
  color: #150f37;
}

.inventory-value-label {
  padding: 10px 0 8px 0;
  font-size: 14px;
  font-weight: 300;
}

.grid-column-styles {
  grid-template-columns: 1fr fit-content(20%) 1fr fit-content(20%) 1fr;
}

.disabled-arrow-btn {
  color: #e0e0e1;
}

.align-center {
  text-align: center;
}

/* Current Graph  */
.inventory-current-border {
  align-self: center;
  display: flex;
  flex-flow: column;
  align-items: center;
  border: dashed 1px #b2b2b5;
  border-radius: 6px;
  min-height: 260px;
  .gas-forecast-line {
    display: none;
  }
  .diesel-forecast-line {
    display: none;
  }
}

/* Inventory Dropdown */
.inventory-dropdown {
  justify-content: flex-end;
}

.inventory-dropdown-arrow {
  width: 24px;
  height: 24px;
  opacity: 0.64;
}

.inventory-dropdown-btn {
  padding: 8px 15px 8px 10px;
  font-size: 14px;
  color: #bdbdbd;
  border: solid 1px #bdbdbd;
}

/* Inventory Legend */
.inventory-legend-element {
  width: 17px;
  height: 15px;
  margin: 10px 5px;
  border-radius: 3px;
  border: solid 1px #e0e0e1;
}

.inventory-legend-label {
  font-size: 12px;
  color: #89888c;
}

.legend-gas {
  background-color: #89cf00;
}

.legend-diesel {
  background-color: #ffe600;
}

.legend-total {
  background-color: #395600;
}

.legend-additional {
  background-color: #767676;
}

/* Time-clock */
.forecast-graph-set-updated-text {
  color: #666666;
  font-size: 12px;
  margin-top: -5px;
}

/*forecast line*/
.gas-forecast-line {
  display: inline-block;
  background: #009900;
  width: 36px;
  height: 3px;
  position: absolute;
  z-index: 9;
  margin-left: 13px;
}

.diesel-forecast-line {
  display: inline-block;
  background: #ffd000;
  width: 36px;
  height: 3px;
  position: absolute;
  z-index: 9;
  margin-left: 51px;
}

.forecast-label {
  margin-top: -25px;
  font-size: 12px;
  color: #89888c;
}

.table-title {
  font-size: 24px;
  font-weight: 900;
  color: #161038;
}

.published-graph-set {
  .inventory-value-label {
    font-size: 14px !important;
  }
}

/*Table*/
.forecast-table,
.to-publish-table,
.unpublished-table,
.saved-versions-table {
  .ant-table-thead {
    .ant-table-cell {
      font-weight: 400;
      background-color: #fff;
    }
    & .measurement-title,
    .to-publish-measurement-title,
    .saved-versions-title {
      text-align: left !important;
    }
  }

  .ant-table-tbody
    tr:last-child
    > td:not([class*=" to-publish-month-cell-"]):not(.to-publish-measurement-title):not(.saved-versions-title) {
    font-weight: 900;
    color: #3f3e45;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: #fff;
  }
  .ant-table-container {
    border-radius: 0px;
  }
  .ant-table-wrapper {
    border-radius: 0px;
  }
}

.ant-dropdown-menu-item {
  width: 9.5rem;
}

/******** COMBINED TABLE  *****/
.combined-table {
  .ant-table-wrapper {
    border: none;
  }

  .ant-table-title {
    font-size: 24px;
    font-weight: 900;
    color: #161038;
    padding: 5px 20px 5px 20px;
    border: solid 1px #d2d2d4;
  }

  [data-row-key="title_d4"],
  [data-row-key="title_d5"],
  [data-row-key="title_d6"],
  [data-row-key="title_totalCredits"],
  [data-row-key="title_gas"],
  [data-row-key="title_diesel"],
  [data-row-key="title_totalObligation"],
  [data-row-key="d4_total"] > td > div > :not(span),
  [data-row-key="d5_total"] > td > div > :not(span),
  [data-row-key="d6_total"] > td > div > :not(span),
  [data-row-key="d4_total"] > td > h3,
  [data-row-key="d5_total"] > td > h3,
  [data-row-key="d6_total"] > td > h3,
  [data-row-key="total_gas"] > td > h3,
  [data-row-key="total_gas"] > td:first-child > div,
  [data-row-key="total_gas"] > td > div > :not(span),
  [data-row-key="total_diesel"] > td > h3,
  [data-row-key="total_diesel"] > td:first-child > div,
  [data-row-key="total_diesel"] > td > div > :not(span),
  [data-row-key="total_obligation"] > td > h3,
  [data-row-key="total_obligation"] > td:first-child > div,
  [data-row-key="total_obligation"] > td > div > :not(span) {
    font-family: "Roboto\ Black", sans-serif;
    font-weight: bold;
  }

  [data-row-key="title_d4"] > td:first-child > h3,
  [data-row-key="title_d5"] > td:first-child > h3,
  [data-row-key="title_d6"] > td:first-child > h3,
  [data-row-key="title_totalCredits"] > td:first-child > h3,
  [data-row-key="title_gas"] > td:first-child > h3,
  [data-row-key="title_diesel"] > td:first-child > h3,
  [data-row-key="title_totalObligation"] > td:first-child > h3 {
    font-size: 24px;
    background-color: #fafafa;
  }

  [data-row-key="title_gas"] > .ant-table-cell-fix-left,
  [data-row-key="title_diesel"] > .ant-table-cell-fix-left,
  [data-row-key="title_totalObligation"] > .ant-table-cell-fix-left,
  [data-row-key="title_d4"],
  [data-row-key="title_d5"],
  [data-row-key="title_d6"],
  [data-row-key="title_totalCredits"],
  [data-row-key="title_gas"],
  [data-row-key="title_diesel"],
  [data-row-key="title_totalObligation"] {
    background-color: #fafafa;
  }
}

.saved-versions-table {
  .table-header-col > div > div > p {
    font-family: "Roboto\ Black", sans-serif;
    font-size: 24px;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #d1cbcb;
  }

  .ant-table-thead > tr > th {
    border-bottom: 2px solid #d1cbcb;
  }

  .ant-table-tbody > tr > td:not(:last-child),
  .ant-table-thead > tr > th:not(:last-child) {
    border-right: 1px solid #d1cbcb !important;
  }

  .ant-table-tbody > tr > td:last-child,
  .ant-table-thead > tr > th:last-child {
    border-right: 0px !important;
  }

  .ant-table-pagination {
    border: 1px solid #e5e7eb !important;
    margin: 0 !important;
    padding: 16px;
  }
}

.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #fff;
}

.measurement-title,
.to-publish-measurement-title,
.saved-versions-title {
  padding-left: 20px !important;
  font-size: 16px;
  color: #161038;
}

.saved-versions-title {
  padding-right: 10px !important;
}

.saved-versions-title:not(:last-child) {
  white-space: nowrap !important;
}

@media (min-width: 2060px) {
  .xxl\:min-w-0 {
    min-width: 0 !important;
  }
}

.current-month-cell,
.next-month-cell,
.previous-month-cell,
.following-month-cell,
th[class*=" to-publish-month-cell-"] {
  padding-left: 5px !important;
  font-size: 16px;
  color: #161038;
  padding-right: 2px !important;
}

.forecast-table {
  .ant-table-tbody > tr > td:last-child {
    padding-right: 120px !important;
  }
  .table-header-col > div > div > p {
    font-family: "Roboto\ Black", sans-serif;
    font-size: 24px;
  }
}

.published-table {
  .ant-table-thead > tr > th {
    padding-right: 0px;
  }
  .ant-table-thead > tr > th:last-child {
    padding-right: 60px !important;
  }
  .ant-table-tbody > tr > td:last-child {
    padding-right: 60px !important;
    padding-left: 30px !important;
  }
}

.unpublished-table {
  .ant-table-thead > tr:first-child th:last-child {
    padding-right: 140px !important;
  }
  .ant-table-tbody > tr > td:last-child {
    padding-right: 140px !important;
  }
}

.ant-table-thead > tr > th {
  font-size: 16px !important;
  font-weight: 900 !important;
  height: 24px;
  padding: 14px 10px 14px 0;
  text-align: center;
}

[data-test="inventory-data-table"] .ant-table-thead > tr > th,
[data-test="total-obligations-table"] .ant-table-thead > tr > th,
[data-test^="to-publish-table-"] .ant-table-thead > tr > th {
  text-align: right !important;
}

[data-test^="to-publish-table-"] .ant-table-thead > tr {
  font-family: "Roboto\ Black", sans-serif;
}

.inventory-header {
  font-size: 20px;
  font-weight: 900;
  color: #161038;
}

.publish-button {
  width: 127px;
  height: 40px;
  padding: 8px 15px;
  border-radius: 6px;
  border: solid 1px #161038;
}

.editable-cell-value-wrap {
  // padding: 5px 12px;
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input.error {
  border: 1px solid #fc532e !important;
}

.data-tab-background {
  height: 100px;
  width: 100%;
  padding: 30px 0 30px 80px;
  object-fit: contain;
  background-color: #f9f9f9;
}

.note-text {
  font-size: 12px;
  color: #89888c;
}

.add-comments-styles {
  font-size: 12px;
  color: #89888c;
}

.forecast-dropdown {
  height: 1.5rem;
  // position: absolute; - removed for styling arrows
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  z-index: 100;
  margin-top: 0.5px;

  .anticon-ellipsis {
    position: relative;
    top: -2px;
  }
}

.forecast-dropdown-menu {
  .ant-dropdown-menu-item {
    background-color: #ffff;
    width: 100%;
  }

  .forecast-dropdown-metrics {
    .ant-dropdown-menu-title-content {
      flex: none;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      width: 100%;
      column-gap: 0.75rem;
    }
  }
}

.forecast-checkbox {
  margin-right: -7px;
}

[class*="forecast-action-reason-"] {
  height: 44px;
  margin-bottom: -10px;
}

.to-publish-modal-bg {
  z-index: 100;
}

// write media queries here
@media (min-width: 768px) {
  .to-publish-modal-table-holder {
    min-width: 600px;
  }
}

@media (min-width: 720px) and (max-width: 1440) {
  .inventory-graph-set {
    gap: 0rem !important;
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  .to-publish-modal-table-holder {
    min-width: 100%;
  }

  .unpublished-table {
    .ant-table-thead > tr:first-child th:last-child {
      padding-right: 10px !important;
    }
    .ant-table-tbody > tr > td:last-child {
      padding-right: 10px !important;
    }
  }

  .forecast-table {
    .ant-table-tbody > tr > td:last-child {
      padding-right: 10px !important;
    }
    .ant-table-thead > tr > th:last-child {
      padding-right: 10px !important;
    }
  }
}

.to-publish-modal-table-holder {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.test-cr-1 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  z-index: 100;
  width: 200px;
  padding: 8px;
}

.non-expanding-child {
  flex-basis: auto;
  flex-shrink: 1;
}

.expanding-child {
  flex-grow: 1;
}

.total-table {
  > thead > tr > th {
    font-weight: 700;
    font-size: 14px;
    white-space: nowrap;
  }
  > tbody > tr:last-child {
    font-weight: 700;
    color: #161038;
  }
    > tbody > tr > td:first-child {
    white-space: nowrap;
  }
}