.bp-component-table {
  --bg-color: #fafafa;
}

.header-color {
  th {
    background-color: var(--bg-color);
  }
}

.bp-component-table .table thead th {
  border-bottom: 1px solid #ccc;
  background-color: transparent;
}
