.plead {
  height: 32px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: 0.2px;
  color: #111;
}

.plead-img {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: 0.2px;
  color: #111;
}

.label {
  width: 122px;
  height: 28px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.09px;
  color: rgba(17, 17, 17, 0.57);
  margin-left: 0px;
}

.mt-values {
  width: auto;
  height: 28px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.09px;
  text-align: right;
  color: #111;
}

.border-bottom {
  width: 360px;
  height: 1px;
  border: solid 1px #ededed;
}

.input-text-div {
  width: 315.1px;
  height: 113px;
  padding: 11px 0 0 15px;
  border: solid 1px #dedede;
}

.input-text-label {
  width: 245px;
  height: 21px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.15px;
  margin-bottom: 12px;
  color: #111;
}

.help-text {
  width: 315.1px;
  height: 32px;
  margin-bottom: 32px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.2px;
  color: rgba(17, 17, 17, 0.64);
}
