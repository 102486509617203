@import "@bphxd/ds-core-react/lib/scss/bp-core.scss";

.addBatch {
  &__btn-group {
    @apply flex space-x-2;
    .btn {
      @apply hover-shadow-elevated rounded-none;

      &-light {
        @apply border-black;
      }
    }
  }

  &__form {
    @apply container mx-auto space-y-5 px-5 py-6 bg-secondary;
  }

  &__field {
    input,
    select {
      @apply border border-primary bg-white;
    }

    .datePicker {
      padding-left: 40px;
    }
  }

  &__summary {
    @apply border border-primary bg-white;
    // outline: 1px solid $border-gray;

    &-title {
      @apply capitalize border-bottom border-primary;
      font-size: 16px;
      padding: 0.75rem;
    }

    &-list {
      @apply space-y-2;
      padding: 0.75rem;
      font-size: 15px;
      color: var(--light-typography-text-body-light);
    }

    &-notification {
      @apply w-full rounded text-xs p-3 shadow bg-secondary border-1 border-primary;
    }
  }

  &__success {
    @apply container mx-auto p-4 space-y-6 lg:space-y-10;
    background-color: var(--light-fill-1-light-fill-primary);
    height: 400px;

    &-alert {
      height: 46px;
      padding: 10px;
      margin: 1rem 0;
      border-radius: 3px;
      border: solid 1px
        var(--light-alerts-notification-validation-success-border-light);
    }

    &__create-another {
      height: 96px;
      font-size: 28px;
      font-weight: 200;
      line-height: 1.5;
      letter-spacing: 0.2px;
    }
  }
}
