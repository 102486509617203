.card-container {
  min-width: 510px;
  width: 100%;
  height: 180px;
  margin: 0 20px 0 0;
  padding: 18px 0 23px;
  background-color: #fff;
  cursor: pointer;
  &:hover {
    .card-header-heading {
      text-decoration: underline;
    }
    box-shadow: 0 20px 30px 0 rgba(17, 17, 17, 0.08);
  }
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
  margin-left: 19px;
  margin-right: 15px;
}

.card-header-heading {
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: normal;
}

.card-status {
  width: 50px;
  height: 27px;
  font-size: 10px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-status-open {
  background-color: #ecfafe;
}

.card-status-closed {
  background-color: #ededed;
}

.card-status-error {
  background-color: #ffedf0;
}

.card-divider {
  width: 100%;
  height: 1px;
  margin: 11px 0 24px;
  background-color: #dedede;
}

.card-body-item-header {
  margin-bottom: 0px;
  font-size: 16px;
  color: rgba(74, 74, 74, 0.8);
  text-align: left;
}

.card-body-item-count {
  font-size: 20px;
  font-weight: 300;
  color: #111;
  text-align: left;
}
