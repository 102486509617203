
.bp-card-value-group {
  @apply gap-5 flex flex-col;

  h1 {
    @apply font-bold text-lg;
  }

  h2 {
    @apply text-gray-700;
  }

  .bp-card-value-group-content {
    @apply gap-5 flex;
  }
}

.bp-card-value {
  @apply gap-5 flex-col items-start justify-start bg-gray-50 border-none;


  dd {
    @apply text-4xl font-bold;
  }

  dt {
    @apply text-sm font-normal lg:flex-shrink-0;
    color: #3f3e45; // TODO: This should be on tailwind theme but not found on guideline
  }
  
}