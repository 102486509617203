.cost-calc-spinner-background {
  background-color: white !important;
  height: 540px !important;
}

.cost-calculation-feedstock-table {
  overflow-x: auto;
  width: 100%;
  .react-table-pagination {
    white-space: nowrap;
  }

  .cost-calculation-table {
    /* Ensure table takes up the full width of its container */ /* Enable horizontal scrolling if table exceeds container width */
    white-space: nowrap; /* Prevent table cells from wrapping */
    overflow-x: auto;
    border-spacing: 0px;
    border-collapse: separate;
  }
  .cost-calculation-table thead tr th:nth-child(n + 3):nth-child(even) {
    border-left: thin solid;
    border-left-color: inherit;
  }

  .cost-calculation-table thead tr th:first-child {
    border-left: none; /* Remove border left for the first column in the header */
    position: sticky;
    z-index: 10;
    left: 0;
  }

  .cost-calculation-table tbody tr td:first-child {
    border-left: none; /* Remove border left for the first column in the header */
    position: sticky;
    z-index: 10;
    left: 0;
  }

  .cost-calculation-table thead tr th:nth-child(2) {
    border-left: none; /* Remove border left for the first column in the header */
    position: sticky;
    z-index: 10;
    left: 255.8px;
  }

  .cost-calculation-table tbody tr td:nth-child(2) {
    border-left: none; /* Remove border left for the first column in the header */
    position: sticky;
    z-index: 10;
    left: 255.8px;
  }

  .no-consumption-data {
    width: 100%;
    height: 39px;
    font-family: Roboto;
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.15px;
    text-align: center;
    color: #111;
    margin-top: 109px;
    margin-bottom: 24px;
  }

  .no-consumption-refinery-data {
    width: 100%;
    height: 32px;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.78;
    letter-spacing: 0.2px;
    text-align: center;
    color: #111;
  }

  .cost-calculation-table thead > tr:nth-of-type(3) th:nth-child(3) {
    border-left: none; /* Remove border left for the first column in the header */
    position: sticky;
    z-index: 10;
    left: 367.5px;
  }

  .cost-calculation-table tbody tr td:nth-child(3) {
    border-left: none; /* Remove border left for the first column in the header */
    position: sticky;
    z-index: 10;
    left: 367.5px;
  }

  thead .fixed-column,
  tbody .fixed-column {
    border-right: 1px solid #ddd; /* Ensure alignment with other columns */
  }

  /* For the body */
  .cost-calculation-table tbody tr td:nth-child(n + 3):nth-child(even) {
    border-left: thin solid;
    border-left-color: inherit;
  }

  .cost-calculation-table thead tr th:nth-child(n + 3) {
    border-left: thin solid;
    border-left-color: inherit;
    background-color: #fefefe;
  }

  .cost-calculation-table tbody tr td:first-child {
    border-left: none; /* Remove border left for the first column in the body */
  }

  .cost-calculation-table thead tr:nth-child(2) th:nth-child(n + 2) {
    font-weight: bold;
  }

  .table-shadow-effect {
    box-shadow: 3px 2px 4.5px 0.5px rgb(88 87 87 / 8%);
  }

  .table-shadow-empty-row {
    background-color: #fafafa;
    border-left: none !important;
  }

  .popover-open {
    background-color: rgba(17, 17, 17, 0.02) !important;
  }

  .table-shadow-effect-last-row {
    box-shadow: 3px 0px 2.5px 0.5px rgb(88 87 87 / 8%);
  }

  .cost-calc-table-spinner {
    position: absolute !important;
    left: 40% !important;
    background-color: transparent !important;
    margin-top: 105px !important;
  }

  .cost-calculation-table thead tr:first-child th,
  .cost-calculation-table thead tr:nth-child(2) th {
    border-bottom: solid 1px #666; /* Adjust thickness and color as needed */
  }

  .cost-calculation-table thead tr:nth-child(3) th {
    border-bottom: solid 1px #ccc; /* Adjust thickness and color as needed */
    text-align: right;
  }

  /* First column */
  .cost-calculation-table td:nth-child(1),
  .cost-calculation-table th:nth-child(1) {
    width: 273.1px; /* Set a fixed width for the first column cells */
  }

  /* Second column */
  .cost-calculation-table td:nth-child(n + 2),
  .cost-calculation-table th:nth-child(n + 2) {
    width: 107px;
  }

  /* From the third column onwards */
  .cost-calculation-table td:nth-child(n + 4),
  .cost-calculation-table th:nth-child(n + 4) {
    width: 116px;
  }

  .cost-calculation-table tbody td:not(:first-child) {
    text-align: right;
  }

  .cost-calculation-table thead tr:last-child {
    background-color: #fefefe;
  }

  .table-last-row {
    border-top: 16px !important;
    background-color: #c1e4ca !important;
    font-weight: bold;
  }

  .cost-calculation-table tbody tr:last-child {
    margin-top: 16px !important;
  }

  .table-data-row {
    background-color: #e7f5e6;
    font-weight: bold;
  }

  .table-unit-row {
    background-color: #f8f8f8 !important;
  }

  .status-badge {
    padding: 4px 9.5px;
    border-radius: 3px;
    background-color: #ecfafe;
  }

  .more-details-section {
    box-shadow:
      0px 24px 25px -20px rgba(0, 0, 0, 0.1) inset,
      0px -24px 25px -20px rgba(0, 0, 0, 0.1) inset !important;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .cursor-pointer:hover {
    font-weight: 500;
    text-decoration: underline;
  }
}
