.mb-action-modal-344 {
  .modal-content {
    width: 344px !important;
    border-radius: 0px !important;
  }
  .modal-body {
    width: 344px !important;
  }
}

.mb-action-modal-666 {
  .modal-content {
    width: 666px !important;
    border-radius: 0px !important;
  }
  .modal-body {
    width: 666px !important;
  }
}
