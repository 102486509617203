.bp-filter {
  @apply flex flex-grow items-end gap-4 flex-wrap bg-white py-10 px-7;
  box-shadow: 0 20px 30px 0 rgba(35, 35, 35, 0.2);

  .bp-filter-content {
    @apply flex-col flex flex-grow gap-4 sm:flex-row max-w-full;

    .bp-filter-buttons {
      @apply flex gap-4 pt-6;

      &.--expanded {
        @apply mt-auto;
      }
    }

    .bp-filter-layout-two-cols {
      @apply flex flex-grow gap-4 flex-wrap max-w-full;
    }

    .bp-filter-layout-single-row {
      @apply flex flex-grow gap-4 flex-wrap max-w-full;

      .bp-formfield {
        @apply sm:grow-0;
      }
    }
  }

  button {
    @apply p-2;
  }
}

.toggle-dropdown {
  @apply bg-gray-50 rounded w-48 text-left;
  border: 1px solid theme("colors.gray.900");

  .ant-space {
    @apply flex justify-between;
  }
  .ant-space-item {
    @apply flex;
  }
}

.filter-item {
  @apply bg-gray-50 p-2;
  min-width: 192px;
  border: 1px solid theme("colors.gray.200");
}
