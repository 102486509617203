.legend-icon {
  display: inline-block;
  width: 12px;
  height: 12px;
  transform: translate(0px, 2px);
}

.legend-text {
  width: 50px;
}

.legend-text,
.legend-arial {
  font-family: Arial;
}
.credits-deficits-text {
  stroke-width: 0.2;
  font-family: Arial;
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.3px;
}

.legend-info-first {
  border-right: solid 1px #b7b7b7;
}
