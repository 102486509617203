.results-table-compliance-rules-engine {
  @apply min-h-[100px] w-full;
}

.results-table-compliance-rules-engine thead th {
  text-transform: none !important;
}
.results-table-compliance-rules-engine tr th {
  @apply bg-transparent text-[#111] whitespace-nowrap;
  border-bottom: 1px solid #ededed !important;
}

.results-table-compliance-rules-engine + .react-table-pagination {
  padding-bottom: 9.5rem;
}

.results-table-compliance-rules-engine tbody {
  overflow: visible !important;
}
.results-table-compliance-rules-engine tbody tr td:last-child {
  padding: 0px !important;
}

/* default column width */
.results-table-compliance-rules-engine td,
.results-table-compliance-rules-engine th {
  min-width: 130px !important; /* Set a fixed width all column cells*/
}
/* First column */
.results-table-compliance-rules-engine td:nth-child(1),
.results-table-compliance-rules-engine th:nth-child(1) {
  min-width: 25px !important; /* Set a fixed width for the second column cells */
}
/* First column */
.results-table-compliance-rules-engine td:nth-child(2),
.results-table-compliance-rules-engine th:nth-child(2) {
  min-width: 25px !important; /* Set a fixed width for the second column cells */
  .btn {
    padding: 0px !important;
    margin-top: -2px !important;
  }
}
/* second column */
.results-table-compliance-rules-engine td:nth-child(3),
.results-table-compliance-rules-engine th:nth-child(3) {
  min-width: 322px !important; /* Set a fixed width for the second column cells */
}
/* 3rd column */
.results-table-compliance-rules-engine td:nth-child(4),
.results-table-compliance-rules-engine th:nth-child(4) {
  min-width: 190px !important; /* Set a fixed width for the second column cells */
}

/* last action column */
.results-table-compliance-rules-engine td:last-child,
.results-table-compliance-rules-engine th:last-child {
  min-width: 25px !important;
}

.table-row-selected-bg {
  background-color: #eef0f5 !important;
}

.results-table-compliance-rules-engine td {
  vertical-align: middle !important;
}

.results-table-compliance-rules-engine tbody tr td:nth-child(2),
.results-table-compliance-rules-engine thead tr th:nth-child(2) {
  white-space: nowrap;
}

.rules-engine-results-list {
  .react-table-pagination {
    padding-top: 0.5%;
  }
}
