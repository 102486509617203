.accordion-body,
.accordion-button {
  @apply px-0.5;
}

.accordion-button {
  @apply text-xl items-center;
}

.view-doc__max-scroll {
  max-height: 100vh;
}

.primary-btn {
  background-color: var(--bs-btn-bg) !important;
}

.form-manual-entry,
.view-document {
  .flatpickr-calendar.static {
    position: relative !important;
  }
}
