.ant-table-container {
  border-width: 1px;
  border-radius: 0.25rem;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
  background-color: rgb(229 231 235);
}

.ant-table-tbody > tr > td {
  border-bottom: 1px solid #d1cbcb;
  text-align: center;
  padding: 10px 0px;
  white-space: pre-wrap;
}

.ant-table-thead > tr > th {
  border-bottom: 2px solid #d1cbcb;
  text-align: center;
  font-weight: bold;
  padding: 14px 20px;
  color: #161038;
}

.ant-table-tbody tr:last-child > td {
  border-bottom: 0px;
}

.ant-pagination-item-active {
  border: solid 2px #161038 !important;
}

.ant-pagination-item-active a {
  color: #161038;
}

.ant-table-pagination.ant-pagination {
  margin: 16px 16px;
}

.ant-pagination-prev,
.ant-pagination-next {
  display: none;
}

.ant-pagination-item {
  border: 0;
}

.ant-pagination-item a {
  font-weight: bold;
}

.ant-table-cell {
  color: rgb(75 85 99);
}



.ant-checkbox-wrapper {
  padding-right: 10px;
  padding-left: 10px;
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-item,
.ant-select-item-option,
.ant-select-item-option-selected,
.ant-select-open .ant-select-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
  background-color: white !important;
}

.ant-table-selection-column {
  padding-left: 0 !important;
}
