.custom-width-for-page {
  width: 835px;
}

.cost-calc-layout {
  z-index: 20;
  width: fixed;
}

.margin-for-border-line {
  margin-top: 0;
  margin-bottom: 0;
}

.last-calc-user {
  width: 356px;
  height: 22px;
  margin-top: -6px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 0.2px;
  color: #111;
}

.heading-background {
  height: 39px;
  background-color: #fff;
}
.text-background {
  height: 21px;
  margin: 9px 12px 9px;
  font-family: Roboto, Arial, sans-serif;
  font-size: 12px;
  color: #111;
}

.background-for-input-box {
  height: 64px;
  background-color: #fff;
}

.no-consumption-data-header {
  background-color: #a1a1a1 !important;
  border: none !important;
  cursor: not-allowed; /* Change cursor to not-allowed */
  pointer-events: none;
}

.save-button-editable-header {
  background-color: #a1a1a1 !important;
  border: none !important;
  cursor: not-allowed; /* Change cursor to not-allowed */
  pointer-events: none;
  width: auto !important;
  height: 40px !important;
  margin: 32px 0px 0px 0px !important;
  border-radius: 0 !important;
}

.text-box-background {
  width: 125px !important;
  height: 36px !important;
  margin: 14px 30px 13px 12px !important;
  border: solid 1px #dedede !important;
}
.text-box-input-value {
  width: 59px !important;
  height: 24px !important;
  margin: 6px 15px 6px 0 !important;
  padding: 0 0 0 15px !important;
  border: 1px #dedede !important;
}
.addon-textbox-background {
  width: 36px;
  height: 36px;
  margin: -0.8px -0.8px 0px 15px;
  padding: 6px;
  border: solid 1px #dedede;
  background-color: #f2f2f2;
}
.addon-text-value {
  width: 24px;
  height: 24px;
  font-family: Roboto, Arial, sans-serif;
  font-size: 13px;
  text-align: center;
  color: rgba(17, 17, 17, 0.64);
}
.materialid-textheading {
  width: 143px;
  height: 23px;
  margin: 20px 12px 20px 12px;
  font-family: Roboto, Arial, sans-serif;
  font-size: 13px;
  color: rgba(17, 17, 17, 0.64);
}
.cancel-button-background {
  width: 83px !important;
  height: 40px !important;
  margin: 32px 0px 0px 0px !important;
  border: solid 1px #ccc !important;
  background-color: rgba(255, 255, 255, 0) !important;
  border-radius: 0 !important;
}
.save-button-background {
  width: auto !important;
  height: 40px !important;
  margin: 32px 0px 0px 0px !important;
  background-color: #404040 !important;
  border-radius: 0 !important;
}

.header-bottom-line {
  border: solid 1px #666;
}
