.rule-engine-editor {
  .draggable-button {
    height: 80px;

    padding: 16px;
    border-radius: 5px;
    border: solid 1px #ccc;
    background-color: rgba(255, 255, 255, 0);
    align-items: center;
    display: flex;
  }
  .draggable-button-disabled {
    border: solid 1px #ededed;
    background-color: rgba(242, 242, 242, 0);
    color: rgba(17, 17, 17, 0.5);
    height: 80px;

    padding: 16px;
    border-radius: 5px;
    border: solid 1px #ccc;
    align-items: center;
    display: flex;
  }

  .block-header {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: 0.1px;
    color: rgba(17, 17, 17, 0.92);
  }

  .droppable-section-button {
    min-width: 390px;
    width: 390px;
    height: 95px;

    border-radius: 5px;
    box-shadow: 0 9px 14px 0 rgba(35, 35, 35, 0.14);
    background-color: #fff;
    align-items: center;
    display: flex;
    padding: 16px;
  }

  .droppable-section-button,
  .droppable-section-button-selected {
    min-width: 390px;
    width: 390px;
    height: 95px;

    border-radius: 5px;
    box-shadow: 0 9px 14px 0 rgba(35, 35, 35, 0.14);
    background-color: #fff;
    border: 1px solid black;
    align-items: center;
    display: flex;
    padding: 16px;
    justify-content: space-between;
  }
  .droppable-section-button div,
  .droppable-section-button-selected div {
    text-align: left; /* Ensures text is aligned to the left */
    flex: 1; /* Ensures the text container takes up available space */
    align-items: center;
    white-space: normal; /* Ensures text wraps to the next line */
    word-wrap: break-word; /* Breaks long words to wrap within the container */
    overflow: hidden; /* Hides any overflow content */
  }

  .action-hover-button {
    width: 36px;
    height: 36px;

    padding: 6px;
    border-radius: 3px;
    border: solid 1px #ccc;
    background-color: #fff;
  }
  .action-hover-button:hover {
    width: 36px;
    height: 36px;

    padding: 6px;
    border-radius: 3px;
    border: solid 1px #404040;
    background-color: #fff;
  }

  .action-hover-button-disabled {
    width: 36px;
    height: 36px;

    padding: 6px;
    border-radius: 3px;

    border: solid 1px #c2bdbd;
    background-color: rgba(242, 242, 242, 0);
    color: rgba(17, 17, 17, 0.5);
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .fadeInAnimation {
    animation: fadeIn 1s ease-out;
  }

  .dropping-section-view {
    width: 390px;
    border-radius: 5px;
    border: dashed 1px #b6b6b6;
    background-color: #dedede;
  }
}
