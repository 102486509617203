
.bp-notification {
  @apply flex flex-grow rounded p-3 text-sm items-center justify-between;

  
  .bp-notification-text {
    @apply flex gap-3 items-center;
  }

  .bp-notification-close {
    @apply cursor-pointer;
  }

  &.--hidden {
    @apply hidden #{!important};
  }
  &.--info {
    @apply bg-gray-100;
  }

  &.--success {
    @apply bg-bp-green-100;
  }

  &.--progress {
    @apply bg-yellow-700;
  }

  &.--warning {
    background-color: rgb(250, 163, 23, 0.3); // Temporary till nabil add it to colour
  }

  &.--error {
    background-color: rgb(252, 83, 46, 0.3); // Temporary till nabil add it to colour

  }
}