.bp-core-hr {
  @apply h-[1px] my-8;
}

.cost-calc-back-button {
  margin-left: 43px;
  margin-top: 32px;
  margin-bottom: 40px;
  width: 209px;
  height: 40px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: rgba(17, 17, 17, 0.84);
  padding-left: 10px !important;
  padding-right: 10px !important;
}
