@import "~@bphxd/ds-core-react/lib/scss/bp-core";

.disabled-field {
  color: gray;
}

.btn-bg-br-black {
  border-color: $dark-gray-600;
  background-color: $dark-gray-600;
}

.btn-bg-br-gray {
  border-color: $light-gray-300 !important;
  background-color: $light-gray-300 !important;
}

.link-btn {
  border-radius: 0px;
  margin-right: 20px;
  border-color: $light-gray-500 !important;
}

.alert-icon--danger {
  color: $danger-dark;
}

.alert-icon--info {
  color: $info-dark;
}

.alert-icon--warning {
  color: $warning-dark;
}

.spinner-container {
  text-align: center;
  padding-top: 10%;
  align-self: center;
  font-family: Roboto, sans-serif;
  font-size: 13px;
}

.results-table-container, .results-table-buttons {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}

.rule-engine-results-rule-name {
  font-family: Roboto;
  font-size: 26px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #111;
}
