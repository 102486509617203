.actualisation-date-range-right + .flatpickr-calendar {
  right: 0px !important;
}

.actualisation-date-range-left + .flatpickr-calendar {
  left: 0px !important;
}

.filter-datepicker-container {
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid lightgray;
}

.filter-datepicker-container:hover {
  border: 1px solid gray;
}
