.form-check-reverse {
  margin-bottom: 6px;
  margin-top: 10px;
}
.primary-btn {
  background-color: var(--bs-btn-bg) !important;
}
.customize-table-item {
  .form-check-label {
    margin-top: 3.5%;
  }
}

.customize-col-button:hover {
  border: solid 1px #111 !important;
}
