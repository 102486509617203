.bp-error-page-not-found {
   @apply w-full flex px-12 my-12 lg:px-24 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-x-4 gap-y-4 ;
    
    
   .link-home-button{
    @apply flex items-center justify-between text-white rounded bg-bp-green-800 w-32 p-2 my-3 font-bold border-indigo-500 hover:opacity-50 
  }  

  .label-header-error-code {
    @apply font-bold text-2xl text-indigo-500;
  }

  .label-header-error {
    @apply text-3xl  text-indigo-500;
  }

  .label-error-details {
    @apply text-3xl font-medium text-indigo-500;
  }

  .bp-error-page-not-found-content-details{
    @apply w-full flex items-center md:px-12 lg:px-12 xl:px-12;
  }

  .bp-error-page-not-found-content{
    @apply flex flex-col
  }

  .bp-error-page-not-found-content-image{
   @apply  w-full flex items-center;
  }

  .error-content-link a{
    @apply inline text-blue-500 cursor-pointer underline;
  }
      
  }
  
  