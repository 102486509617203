@import "@bphxd/ds-core-react/lib/scss/bp-core.scss";

.historical-filters {
  @apply row row-cols-md-auto g-4 align-items-center;

  .flatpickr-wrapper {
    width: 320px;
    .form-control {
      @apply rounded-0;
      border: solid 1px #ccc;
    }
  }
  .dropdown {
    &-toggle {
      @apply rounded-0 px-3.5;
      border: solid 1px #ccc;
      min-width: 170px;
      &:hover {
        @apply rounded-0;
        border: solid 1px #ccc;
      }

      svg {
        margin-left: auto;
      }
    }
  }
}
