.batch-input-container {
  display: flex;
  align-items: center;
}

.datePicker {
  padding-left: 40px !important;
}

.modal-title {
  font-size: 1.1rem;
}
