.error-pos-modal {
  .ant-modal-header {
    border-bottom-width: 0;
  }

  .ant-modal-body {
    padding-top: 0px;
  }
}

.formfield-label label {
  font-weight: inherit !important;
}
