.heading-section {
  font-size: 26px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.2px;
  color: #111;
}

.cost-calc-object-exists {
  margin-top: 107px !important;
}
