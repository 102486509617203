@import "@bphxd/ds-core-react/lib/scss/bp-core.scss";

.split-shipment {
  &__modal {
    .modal-content {
      @apply rounded-none;
    }
    .modal-header {
      @apply border border-b;
    }
    .modal-body {
      @apply py-8 bg-secondary;
    }
  }

  &__form {
    @apply mx-auto row g-5;
  }

  &__btnGroup {
    @apply flex space-x-3;
    .btn {
      @apply hover-shadow-elevated rounded-none;
    }
  }

  &__field {
    .form-select,
    .input-group {
      background-color: white;
    }
  }
}
