@import "~@bphxd/ds-core-react/lib/scss/bp-core";

.disabled-field {
  color: gray;
}

.btn-bg-br-black {
  border-color: $dark-gray-600;
  background-color: $dark-gray-600;
}

.btn-bg-br-gray {
  border-color: $light-gray-300 !important;
  background-color: $light-gray-300 !important;
}

.link-btn {
  border-radius: 0px;
  margin-right: 15px;
  border-color: $light-gray-500 !important;
}
