@import "@bphxd/ds-core-react/lib/scss/bp-core.scss";

.bulk-reset__modal {
  .modal-dialog {
    width: 380px;
  }
  .modal-header {
    @apply border border-b px-4;
  }

  .modal-content {
    @apply rounded-none;
  }
  .modal-body {
    @apply py-3 px-5 mt-5 space-y-5 flex flex-col justify-center;
    background-color: var(--fills-light-secondary);
    min-height: 520px;
  }
  .modal-footer {
    @apply p-0 border-none;
  }

  .select-shipments-label {
    line-height: 1.5;
    letter-spacing: 0.15px;
    color: var(--dark-default-typography-text-black-light);
  }

  .shipment-id-title {
    font-size: 16px;
    line-height: 1.75;
    letter-spacing: 0.2px;
    color: var(--light-typography-text-black-light);
  }

  .shipment-list {
    .shipment-item {
      @apply flex items-center mb-5;

      .shipment-label {
        @apply flex flex-col;
      }

      .shipment-date {
        font-size: 12px;
        line-height: 1.75;
        letter-spacing: 0.08px;
        color: var(--light-default-typography-text-grey-light);
      }

      .form-check-input {
        margin-left: auto;
      }
    }

    .spinner {
      @apply flex items-center justify-center w-full h-96;
    }
  }

  .reset-btn-group {
    @apply flex w-full m-0;

    .btn {
      @apply flex-1 rounded-none py-3;

      &:hover {
        color: var(--dark-default-typography-text-black-light);
        background-color: transparent;
      }
    }
  }
}

.bulk-reset-truck-rack-table {
  @apply relative w-5/6;
  max-height: 85vh;
  overflow-y: auto;

  table {
    @apply border-collapse w-full;
    thead {
      position: relative;
      z-index: 10;

      tr th {
        @apply border-b border-gray-300 sticky top-0 bg-none border-t-0;
      }
      tr th[style*="position: sticky"],
      tr td[style*="position: sticky"] {
        position: sticky;
        z-index: 10; /* Ensure pinned columns are above other columns */
      }
    }

    /* Specific styles for the last pinned column */
    .last-pinned-column {
      box-shadow: -4px 0 4px -4px gray inset;
      z-index: 2; /* Ensure it is above other pinned columns */
    }

    tbody tr td {
      @apply border-b border-gray-300 text-center; // Horizontal borders between rows and center align content
    }

    th {
      text-align: center;
      background-color: #f9f9f9;
      white-space: nowrap;
      height: 23px;
      font-size: 13px;
      line-height: 1.77;
      letter-spacing: 0.2px;
      color: var(--dark-default-typography-text-black-light);
    }

    .volume-summary-section {
      @apply sticky bottom-0 h-14 z-20;

      td {
        @apply content-center text-center;
      }

      .volume-summary-label {
        @apply sticky left-0 z-10;
        font-size: 13px;
        line-height: 1.77;
        letter-spacing: 0.2px;
        text-align: right;
        color: var(--light-typography-text-placeholder-light);
      }

      &.first-row {
        bottom: 54px;
        box-shadow:
          0 -4px 6px -1px rgba(0, 0, 0, 0.2),
          0 -2px 4px -1px rgba(0, 0, 0, 0.15);
      }
    }
  }
}
