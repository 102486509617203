.ant-table-container {
  border-width: 1px;
  border-radius: 0.25rem;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: black;
  border-color: black;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
  background-color: rgb(229 231 235);
}

.ant-table-tbody > tr > td {
  border-bottom: 1px solid #d1cbcb;
  text-align: center;
  padding: 10px 0px;
}

.ant-table-thead > tr > th {
  border-bottom: 2px solid #d1cbcb;
  text-align: center;
  font-weight: bold;
  padding: 14px 20px;
  color: #161038;
}

.ant-table-tbody tr:last-child > td {
  border-bottom: 0px;
}

.ant-pagination-item-active {
  border: solid 2px #161038 !important;
}

.ant-pagination-item-active a {
  color: #161038;
}

.ant-table-pagination.ant-pagination {
  margin: 16px 16px;
}

.ant-popover-inner-content {
  padding: 5px 0px;
}

.ant-pagination-prev,
.ant-pagination-next {
  display: none;
}

.ant-pagination-item {
  border: 0;
}

.ant-pagination-item a {
  font-weight: bold;
}

.ant-popover-arrow {
  display: none;
}

.ant-popover-content {
  border: 2px solid #ededed;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}

.ant-popconfirm .ant-popover-inner-content {
  padding: 15px 15px;
}

.ant-popconfirm .ant-btn-primary {
  background-color: #007f00;
  color: white;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: white;
}

.dashboard-export-csv-file:hover {
  color: inherit;
  background-color: transparent;
  text-decoration: underline;
}

#button-select-actions svg {
  display: block;
}

.ant-spin-dot-item {
  background-color: darkslategray;
}

.ant-picker {
  border-radius: 5px !important;
}

#button-select-actions {
  border-radius: 5px !important;
}

.ant-table-wrapper {
  min-width: 100%;
}
