.fifo-filter-content-section {
  .filter-by {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.78;
    letter-spacing: 0.15px;
    color: #111;
  }
}
.btn--report-settings {
  padding-right: 18px;
  padding-left: 10px;
  padding-bottom: 8.5px;
  padding-top: 8.5px;
}

.cost-calc-object-exists {
  margin-top: 107px !important;
}

.btn--download-report {
  padding-right: 18px;
}

.modal--separator {
  border: none;
  height: 1px;
  color: #ededed;
  background-color: #ededed;
}

/* Remove all btn bellow once bpCore version is updated */
.btn-clear--text:hover {
  padding-bottom: 2px;
  border-bottom: solid 1px #111;
}

.btn-clear {
  border: none;
  color: #111;
  background-color: transparent;
}
.btn-clear:hover {
  color: #111;
  background-color: transparent;
  .btn-clear--text {
    padding-bottom: 2px;
    border-bottom: solid 1px #111;
  }
}

.btn-clear:active {
  color: #111;
  background-color: transparent;
  .btn-clear--text {
    padding-bottom: 2px;
    border-bottom: solid 1px #8d8d8d;
  }
}
