.nav-sidebar .collapse {
  visibility: visible !important;
}

.nav-sidebar .nav.flex-column .nav-link {
  display: flex;
  flex-direction: row;
}

.side-menu-underline {
  color: rgba(17, 17, 17, 0.64);
  font-size: 14px;
  padding: 0.5rem 1.875rem;
  margin-bottom: 0.125rem;
}
