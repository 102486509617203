.batch-details-actions-buttons {
  button {
    border-color: #cccccc;
  }
}

.batches-table-container {
  overflow-x: auto;
}

.sticky-table-container {
  overflow-x: auto;

  table {
    border-collapse: collapse;
  }

  th,
  td {
    border-left: none;
    border-right: none;
  }

  tr:not(:last-child) td,
  tr:not(:last-child) th {
    border-bottom: 1px solid #dee2e6;
  }

  tr:last-child td,
  tr:last-child th {
    border-bottom: none;
  }

  .sticky-column {
    position: sticky;
    right: 0;
    background-color: white;
    z-index: 2;
    box-shadow: -15px 0 20px -5px rgba(0, 0, 0, 0.2);
  }
  .header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .sort-icons {
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .sort-icon {
    font-size: 12px;
    color: #cccccc;
    transition: color 0.2s;
  }
  .sort-icon.active {
    color: black;
    font-weight: bold;
  }
}
