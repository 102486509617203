.Report-Actualisati {
  height: 69px;
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.1px;
  text-align: center;
  color: var(--dark-default-typography-text-black-light);
}
.Background {
  width: 350px;
  height: 256px;
  margin-right: 24px;
  padding: 94px 23px 93px;
  border-radius: 12px;
  background-color: var(--fills-light-primary);
  display: flex;
  flex-direction: column;
}
.h2font-weight-light {
  margin: 24px 0 0;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 0.1px;
  color: var(--dark-default-typography-text-black-light);
}
.header {
  width: 540px;
  height: 35px;
  margin: 0 399px 24px 0;
  font-family: Roboto, sans-serif;
  font-size: 23px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.52;
  letter-spacing: 0.15px;
  color: var(--dark-default-typography-text-black-light);
}
