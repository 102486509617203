.bp-datepicker, .bp-rangepicker {
  &, &:focus, &:hover {
    @apply p-2 border-gray-700 rounded outline-none shadow-none;
  }

  &.ant-picker-disabled {
    @apply border-gray-700;
  }

  .ant-picker-active-bar {
    @apply bg-bp-green-800
  }

  input {
    @apply placeholder-gray-200 #{!important};
  }

  .--has-error & {
    @apply border-red-500;
  }
  
  // Specific for .bp-datepicker
  &.ant-picker-range .ant-picker-clear {
    right: 8px; // Fix clear icon not properly positioned over calendar icon
  }
}

.bp-datepicker-dropdown, .bp-rangepicker-dropdown {
  
  &.--no-header {
    .ant-picker-header {
      @apply hidden;
    }
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected 
  .ant-picker-cell-inner, 
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner  {
    @apply bg-bp-green-800;
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    @apply bg-bp-green-50;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
    @apply border-bp-green-800;
  }

  .ant-picker-today-btn {
    @apply text-bp-green-800;
  }
}