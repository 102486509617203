.bp-modal {

  &.--fullscreen .ant-modal-wrap {
    
    .ant-modal {
      @apply absolute inset-0 max-w-full w-full;
      max-width: 100%;
    }

    .ant-modal-header,
    .ant-modal-content {
      @apply rounded-none w-full;
    }

    .ant-modal-content {
      @apply h-full;
    }
  }

  .ant-modal {
    width: 70%;
    .ant-btn{
      margin-left: 0px !important;
      @apply whitespace-normal;
    }
  }
  
  .ant-modal-header {
    @apply px-8 py-6 rounded-t-lg border-b-gray-100 border-b;
  }

  .ant-modal-content {
    @apply rounded-lg;
  }

  .ant-modal-body {
    @apply p-8 text-base;
  }

  .ant-modal-footer {
    @apply flex pt-0 pb-8 flex-wrap px-8 border-0;
  }

  .ant-modal-close-x {
    display: flex;
    align-items: center;
    justify-content: center;    
  }
}