$light-background-color: #fafafa;
$solid-bar: #0c6636;
$striped-bar: #e6fff2;

.copro-us-shipments-load-more-container {
  display: flex;
}

.copro-us-shipments-load-more-button:hover {
  color: #000000 !important;
}

#copro-collapsible-card-title {
  font-size: 18px;
}

.shipment-cards {
  overflow: auto;
  max-height: 75vh;
}

.shipments-visual-bar-container {
  background-color: $light-background-color;
  padding: 10px;
  margin-bottom: 10px;
  .solid {
    background-color: $solid-bar;
  }
  .striped {
    background-image: linear-gradient(
      -45deg,
      $striped-bar 25%,
      $solid-bar 25%,
      $solid-bar 50%,
      $striped-bar 50%,
      $striped-bar 75%,
      $solid-bar 75%,
      $solid-bar
    );
    background-size: 20px 20px;
  }
}
