.ant-breadcrumb-link{
  font-weight: 500;
  
}
.ant-breadcrumb a{
  font-weight: 700;
  color: black;
  text-decoration: underline;
  font-size: 16px;
}

.ant-breadcrumb > span:last-child a{   
  @apply text-bp-green-800 no-underline;
}

.ant-breadcrumb a:hover{  
  @apply text-green-500; 
}
