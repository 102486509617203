.incoming-docs-table-docmanager {
  @apply min-h-[100px] w-full;
}

.incoming-docs-table-docmanager thead th {
  text-transform: none !important;
}
.incoming-docs-table-docmanager tr th {
  @apply bg-transparent text-[#111] whitespace-nowrap;
  border-bottom: 1px solid #ededed !important;
}

.incoming-docs-table-docmanager + .react-table-pagination {
  padding-bottom: 9.5rem;
}

.incoming-docs-table-docmanager tbody {
  overflow: visible !important;
}
.incoming-docs-table-docmanager tbody tr td:last-child,
.incoming-docs-table-docmanager thead tr th:last-child {
  position: sticky !important;
  right: 0;
  top: 0;
  z-index: 1;
  background-color: #ffffff;
}

.incoming-docs-table-docmanager thead tr th:last-child,
.incoming-docs-table-docmanager thead tr th:nth-last-child(2) {
  background-color: #fafafa !important;
}
.incoming-docs-table-docmanager tbody tr td:last-child {
  padding: 0px !important;
}

.incoming-docs-table-docmanager.scrollable
  tbody
  tr
  td:nth-last-child(2)::before,
.incoming-docs-table-docmanager.scrollable
  thead
  tr
  th:nth-last-child(2)::before {
  content: "";
  position: absolute;
  top: 0;
  left: -10px; /* Adjust this value to control the shadow's position */
  width: 10px; /* Adjust this value to control the shadow's width */
  height: 100%;
  box-shadow: inset -5px 0 10px -9px rgba(0, 0, 0, 0.5);
  pointer-events: none; /* Ensure the shadow doesn't interfere with mouse events */
  z-index: -1; /* Ensure the shadow is behind the element */
}

.incoming-docs-table-docmanager tbody tr td:nth-last-child(2),
.incoming-docs-table-docmanager thead tr th:nth-last-child(2) {
  position: sticky !important;
  right: 53px;
  top: 0;
  z-index: 1;
  background-color: #ffffff;
}

.incoming-docs-table-docmanager tbody tr td div {
  &:hover {
    background-color: #fafafa;
  }
}

/* default column width */
.incoming-docs-table-docmanager td,
.incoming-docs-table-docmanager th {
  min-width: 130px !important; /* Set a fixed width all column cells*/
}
/* First column */
.incoming-docs-table-docmanager td:nth-child(1),
.incoming-docs-table-docmanager th:nth-child(1) {
  min-width: 25px !important; /* Set a fixed width for the second column cells */
}
/* First column */
.incoming-docs-table-docmanager td:nth-child(2),
.incoming-docs-table-docmanager th:nth-child(2) {
  min-width: 25px !important; /* Set a fixed width for the second column cells */
  .btn {
    padding: 0px !important;
    margin-top: -2px !important;
  }
}
/* second column */
.incoming-docs-table-docmanager td:nth-child(3),
.incoming-docs-table-docmanager th:nth-child(3) {
  min-width: 322px !important; /* Set a fixed width for the second column cells */
}
/* 3rd column */
.incoming-docs-table-docmanager td:nth-child(4),
.incoming-docs-table-docmanager th:nth-child(4) {
  min-width: 190px !important; /* Set a fixed width for the second column cells */
}

/* last action column */
.incoming-docs-table-docmanager td:last-child,
.incoming-docs-table-docmanager th:last-child {
  min-width: 25px !important;
}

.table-row-selected-bg {
  background-color: #eef0f5 !important;
}

.incoming-docs-table-docmanager td {
  vertical-align: middle !important;
}

.incoming-docs-table-docmanager tbody tr td:nth-child(2),
.incoming-docs-table-docmanager thead tr th:nth-child(2) {
  white-space: nowrap;
}
