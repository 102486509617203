.bp-core-hr {
  @apply h-[1px] my-8;
}

.bp-revision-request-modal {
  .popover {
    width: 100%;
  }
}

.global-badge-config-center {
  font-family: Roboto;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.9;
  letter-spacing: 1px;
  text-align: center;
  color: #111;
  height: 27px;
  padding: 4px 10px;
  border-radius: 3px;
  background-color: #ecfafe;
  width: 63px;
  height: 27px;
}
