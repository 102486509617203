.bp-tabs {
  @apply flex flex-col sm:flex-row gap-y-2 sm:gap-x-3;
  
  a {
    @apply flex items-center gap-x-2 px-3 py-1 rounded border font-medium 
           text-indigo-500 bg-white border-indigo-500
           hover:opacity-50; 
  
    svg {
      @apply fill-indigo-500;
    }

    &.active {
      @apply bg-indigo-500 text-white hover:opacity-100;

      svg {
        @apply fill-white;
      }
    }

  }
}

.bp-dashboard-tab{

  .ant-tabs-nav-list{
    width:99% !important;
  } 
  .ant-tabs-tab-active{
    @apply text-indigo-500 font-bold #{!important};    
    border: 2px solid #4d4848;
    border-radius: 5px 5px 0px 0px;
    border-bottom: 1px solid white !important;
   
    .tab-header-sub-text{
      @apply  font-bold;
    }
    .tab-header{
      @apply  font-bold;
    }
  }
  .ant-tabs-tab-btn{
    @apply w-full text-indigo-500 font-bold #{!important};
  }
  .ant-tabs-tab { /* For tabs border */
   border-bottom: 2px solid  #4d4848;  
   @apply align-middle items-center px-4 py-0 m-0 w-1/4 text-indigo-500 font-bold;
   
  }
  .ant-tabs-ink-bar {    
    @apply bg-transparent;
  }  
  .tab-header{
    @apply text-sm font-medium;
  }

  .tab-header-sub-text{
    @apply text-xs uppercase;
  }  

  .ant-tabs-tab .anticon {
    margin-right:0px !important;
  }
 
}


.bp-dashboard-tab-small{
@apply w-full;
  .ant-tabs-tab-active{
    @apply text-indigo-500 font-bold #{!important}; 
   }
    .ant-tabs-tab-btn{
      @apply text-indigo-500 font-bold #{!important};
    }
  .ant-tabs-ink-bar {    
    @apply bg-bp-green-800;
  }  
  
 
}



