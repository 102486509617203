.bp-button {
  @apply leading-none p-3 h-max h-10 items-center;
  min-width: 40px;

  &.ant-btn-icon-only {
    @apply justify-center #{!important};
  }

  &.--has-icon {
    @apply flex justify-between;

    .anticon {
      font-size: 20px;
    }
  }

  &.ant-btn {
    @apply border-2 rounded-sm border-indigo-500 uppercase font-bold;
  }

  &.--primary {
    @apply bg-bp-green-800 text-white border-bp-green-800 hover:bg-bp-green-800 hover:text-white hover:border-bp-green-800 disabled:bg-gray-200 disabled:text-gray-600 disabled:border-gray-200;
  }

  &.--secondary {
    @apply bg-white text-indigo-500 border-indigo-500 hover:bg-white hover:text-indigo-500 hover:border-indigo-500 disabled:text-gray-600 disabled:border-gray-200;
  }
  &.--common {
    @apply bg-gray-800 p-2 h-min text-white font-normal border-none hover:text-white hover:border-none text-sm hover:bg-gray-600  disabled:text-gray-600;
  }

  &.--sm1 {
    @apply bg-white h-4 text-indigo-500 border-indigo-500 hover:bg-white hover:text-indigo-500 hover:border-indigo-500 disabled:text-gray-600 disabled:border-gray-200;
  }
}
