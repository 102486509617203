.bp-preview-table {

  .table-header {
    @apply w-full bg-gray-100 rounded-t-lg p-4;
  }

  .scrollable-container {
    @apply overflow-y-scroll border-2 rounded-b-lg max-h-72;
  }

  table {
    @apply w-max min-w-full table-auto overflow-scroll;
  }


  .header-row {
    @apply border-b border-gray-300 h-10;

    th {
      @apply p-4;
    }
  }

  .data-row {
    @apply border-t border-gray-300 h-10;

    td {
      @apply p-4;
    }
  }
}