.fixed-height-tr {
  height: 52px !important;
}
.width-td {
  padding: 0px !important;
}

.top-header {
  border-top: 1px solid #eee !important;
  border-bottom: none !important;
}

.sort-header {
  border-top: 1px solid #eee !important;
  border-bottom: 1px solid #eee !important;
  background-color: #fafafa !important;
  white-space: nowrap !important;
  user-select: none !important;
  cursor: pointer !important;
}

.top-header-text {
  font-size: 15px;
  font-weight: bold;
  color: rgba(17, 17, 17, 0.64);
}

.global-actions {
  background-color: #fafafa !important;
  border-top: 1px solid #eee !important;
  border-bottom: 1px solid #eee !important;
  border-left: none !important;
}

.view-details-section-mb {
  box-shadow:
    0px 24px 25px -20px rgba(0, 0, 0, 0.1) inset,
    0px -24px 25px -20px rgba(0, 0, 0, 0.1) inset !important;
}

.status-badge-mass-balance {
  font-family: Roboto;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.9;
  letter-spacing: 1px;
  text-align: center;
  color: #111;
  height: 27px;
  padding: 4px 17.4px 4px 17.4px;
  border-radius: 3px;
}
