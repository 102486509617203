.card-header-border {
  border-width: 1px;
  border-color: #dedede;
  border-bottom: 0;
}

.card-body-border {
  border-width: 1px;
  border-color: #dedede;
}

.text-color {
  color: #111111;
  opacity: 0.5;
}
