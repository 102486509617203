.status-badge-doc-manager {
  font-family: Roboto;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.9;
  letter-spacing: 1px;
  text-align: center;
  color: #111;
  height: 27px;
  padding: 4px 17.4px 4px 17.4px;
  border-radius: 3px;
}

.doc-manager-action-dropdown-button {
  width: 54px;
  height: 52px;
  border-radius: 0px;
  text-align: center;
  justify-content: center;
}
.doc-manager-action-dropdown:hover,
.doc-manager-action-dropdown:focus,
.doc-manager-action-dropdown-button:hover,
.doc-manager-action-dropdown-button:focus {
  background-color: rgba(17, 17, 17, 0.02);
  width: 54px;
  height: 52px;
  border-radius: 0px;
  text-align: center;
  justify-content: center;
}

.doc-table-expand-button:hover svg,
.doc-table-expand-button:focus svg,
.doc-table-expand-button svg {
  color: rgba(17, 17, 17, 0.7) !important;
}
