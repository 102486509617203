@charset "utf-8";

@import "~@bphxd/ds-core-react/lib/scss/bp-core";
@import "~antd/dist/antd.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Roboto Regular";
    src: url("assets/fonts/Roboto-Regular.ttf");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "Roboto Black";
    src: url("assets/fonts/Roboto-Black.ttf");
    font-weight: 900;
    font-style: normal;
  }

  html,
  body {
    @apply font-normal text-base leading-normal #{!important};
  }
}

// To be removed and styled within component and scoped
.Toastify__toast-container--top-center {
  transform: none !important;
  top: auto !important;
  left: auto !important;
}

.Toastify__toast-container {
  width: 100% !important;
  padding-left: 8rem !important;
  padding-right: 8rem !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;

  @media (max-width: 425px) {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}

.Toastify__close-button {
  align-self: center !important;
}

.Toastify__close-button > svg {
  height: 20px !important;
  width: 20px !important;
}

.Toastify__toast {
  min-height: 0px !important;
  margin-top: 4rem;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  border-radius: 0px !important;
  margin-bottom: 5px !important;
}

.Toastify__toast--success {
  background-color: #c0efb8 !important;
}

.Toastify__toast--error {
  background-color: #f7d5d6 !important;
}

.Toastify__toast--warning {
  background-color: #f7dec7 !important;
}

.bullet-list {
  list-style-type: disc;
}

.anticon-down:hover {
  background-color: rgb(229 231 235);
}

.ant-table-thead {
  font-family: "Roboto Black";
  color: theme("colors.gray.900");
}

.bp-core {
  .collapse {
    visibility: visible;
  }

  .form-control,
  .input-group-text,
  select {
    background-color: white !important;

    &:focus {
      background-color: white !important;
    }
  }

  .form-control:disabled {
    background-color: #fafafa !important ;
    cursor: not-allowed !important;
  }
  select {
    &:disabled {
      background-color: #fafafa !important ;
      cursor: not-allowed !important;
    }
  }
}

a {
  text-decoration: none !important;
}
