.rule-engine-results-table {
  border: 1px solid #ccc;
}
.rule-engine-results-table tbody tr td {
  min-width: 150px !important;
}

.rule-engine-results-table thead th {
  min-width: 150px !important;
  background-color: #fafafa;
}

.rule-engine-results-table th:first-child {
  min-width: 25px !important;
}

.rule-engine-results-table tbody tr td:first-child {
  min-width: 25px !important;
}
