@import "@bphxd/ds-core-react/lib/scss/bp-core.scss";

.createShipment {
  &__btn-group {
    @apply flex space-x-2;
    .btn {
      @apply hover-shadow-elevated rounded-none;
    }
  }

  &__form {
    @apply container mx-auto space-y-5 px-5 py-6 bg-secondary;
  }

  &__field {
    .input-group.input-group-merge.input-group-flatpickr,
    .flatpickr-wrapper,
    .form-select,
    .input-group {
      background-color: white;
    }

    .flatpickr-wrapper {
      width: 100%;
      position: relative; // To position the SVG calendar icon to the right side of the input
      svg {
        right: 1.45rem;
        left: unset !important;
      }
    }
  }

  &__summary {
    background-color: white;
    @apply border-1 border-primary;

    &-title {
      @apply capitalize border-bottom border-primary;
      font-size: 16px;
      padding: 0.75rem;
    }

    &-list {
      @apply space-y-2;
      padding: 0.75rem;
      font-size: 15px;
      color: var(--light-typography-text-body-light);
    }
  }
}
