.incoming-docs-table + .react-table-pagination {
  padding-bottom: 9.5rem;
}

.global-link-to-purchase-table {
  border-collapse: collapse;
  width: 100%;
  min-width: 100%;
  max-height: 850px;
}

.global-link-to-purchase-table thead {
  position: sticky !important;
  top: 0 !important;
  z-index: 10 !important;
}
