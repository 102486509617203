.manual-adjustment-modal {
  background-color: #fafafa;

  select,
  input,
  textarea {
    background-color: white;
    cursor: pointer;
  }
}

.cancel-manual-button {
  border: solid 1px #ccc;
}

.save-manual-button {
  background-color: #404040;
}

.scenario-manual {
  font-size: 23px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.52;
  letter-spacing: normal;
}

.status-manual {
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.77;
  letter-spacing: 0.15px;
  color: #111;
}

.actualized-manual {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.77;
  letter-spacing: 0.2px;
  color: #b4b4b4;
}

.manual-adjustment-dropdown {
  max-height: 500px;
  overflow-y: auto;
}

.manual-adjustment-form {
  .flatpickr-calendar.static {
    width: 100%;
  }
}
