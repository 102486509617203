.consumed-quantity-breakdown-popover {
  .route-location-indicator {
    width: 2px;
    height: 21px;
    background-color: #007f00;
  }
  .route-location-connector {
    width: 100px;
    height: 2px;
    background-color: #007f00;
  }

  .route-mot {
    width: 38px;
    height: 38px;
    border-radius: 999px;
    background-color: #0079b9;
  }

  .popover {
    max-width: fit-content;
    position: absolute !important;
  }

  .popover-arrow {
    left: -50px !important;
  }

  .text-black {
    text-align: center;
  }

  .data-bs-title-p-small-text-black-light {
    font-family: Roboto, Arial, sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.77;
    letter-spacing: 0.2px;
    text-align: center;
    color: #111;
  }

  .first-header {
    margin-right: 25.5px;
  }

  .asmallfont-weight-light-text-right {
    font-family: Roboto, Arial, sans-serif;
    font-size: 13px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.77;
    letter-spacing: 0.25px;
    text-align: right;
    color: rgba(17, 17, 17, 0.64);
    text-decoration: underline;
  }
}
