@import "@bphxd/ds-core-react/lib/scss/bp-core.scss";

.bulk-adjustment {
  &__modal {
    .modal-content {
      @apply rounded-none px-2;
    }
    .modal-header {
      @apply border border-b px-5;
    }
    .modal-body {
      @apply py-2;
      background-color: var(--fills-light-secondary);
      min-height: 520px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  &__spinner,
  &__error {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 380px;
  }

  &__table-header-tank_certified_date {
    text-wrap: nowrap;
  }
}

.bulk-adjustment__layout {
  @apply mt-6 flex space-x-9 items-start;

  .bulk-adjustment__table-container {
    flex: 1 0 auto; // Allow growing, do not shrink, base width auto
    overflow-y: auto;
    max-height: 400px;

    .table {
      border-spacing: 0;

      thead {
        th {
          border-bottom: none;
          font-size: 16px;
          width: 62px;
          line-height: 1.75;
          letter-spacing: 0.2px;
          color: var(--light-typography-text-black-light);
        }
      }

      .remaining-volume-cell {
        color: var(--dark-default-typography-text-grey-light);
        font-size: 12px;
        text-wrap: nowrap;
      }

      .tank-certified-cell {
        text-align: right;
      }
    }
  }
  .bulk-adjustment__volume-container {
    flex: 0 1 auto;
    @apply flex flex-col space-y-5 p-3;

    .text {
      font-size: 16px;
      line-height: 1.75;
      letter-spacing: 0.2px;
      color: var(--light-typography-text-black-light);
      text-wrap: nowrap;
    }
    .bbl {
      margin-top: 30px;
      line-height: 1.78;
      letter-spacing: 0.2px;
      color: var(--dark-default-typography-text-black-light);
    }
  }
}

.bulk-adjustment__actions {
  @apply flex justify-end mt-5;

  .btn {
    @apply border border-secondary;
  }
}

.bulk-adjustment__btn {
  @apply h-10 text-nowrap border border-secondary text-secondary py-2 mx-auto enabled:text-gray-800;
  color: #000000 !important;
  border-radius: 0px;
}

.bulk-adjustment__enter-total-text {
  @apply block w-4/5 mt-6;
  height: 60px;
  font-family: UniversforBP;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.5;
  color: var(--dark-default-typography-text-black-light);
}

.bulk-adjustment-table {
  .table-container {
    display: flex;
    overflow: hidden;
  }

  table thead tr {
    height: 83px;
    align-self: flex-end;
    th {
      background-color: var(--light-fills-fill-secondary-light);
      align-self: flex-end;
    }
  }

  .bulk-adjust-shipments-table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      padding: 12px;
      text-align: left;
      border-bottom: 1px solid #e0e0e0;
    }

    table {
      border-left-width: 1px;
      border-left-color: var(--light-dark-gray-dark-gray-50);
    }

    tbody {
      background-color: #ffffff;
    }

    thead th {
      border-bottom: 1px solid #666666;
    }

    thead tr th {
      vertical-align: bottom !important;
    }

    th {
      background-color: #f9f9f9;
      width: 150px;
    }

    tr:last-child td {
      border-bottom: none;
    }

    td {
      vertical-align: middle;
    }

    td .chip {
      display: inline-block;
      padding: 4px 8px;
      border-radius: 16px;
      background-color: #e0f7fa;
      color: #00796b;
      font-size: 12px;
    }

    td .volume-input {
      width: 100px;
      padding: 4px;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
    }

    td .popover {
      display: inline-block;
      text-decoration: underline;
      cursor: pointer;
    }

    td .metric {
      color: #6c757d;
      font-size: 14px;
      margin-left: 4px;
    }

    th:last-child,
    td:last-child {
      text-align: right;
    }
    .row-highlight {
      background-color: #f1f1f1;
    }
  }

  .table-container .left-table,
  .table-container .right-table {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .table-container .left-table {
    width: 25%;
  }

  .table-container .right-table {
    width: 75%;
  }

  .table-container .left-table table,
  .table-container .right-table table {
    margin-bottom: 0;
  }

  .table-container .left-table::-webkit-scrollbar,
  .table-container .right-table::-webkit-scrollbar {
    display: none;
  }

  .table-container .left-table table th,
  .table-container .right-table table th,
  .table-container .left-table table td,
  .table-container .right-table table td {
    white-space: nowrap;
  }
}
