.bp-core-hr {
  @apply h-[1px] my-8;
}
.rule-editor-title-label {
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: rgba(17, 17, 17, 0.84);
}

.overlay-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent backdrop */
  z-index: 10; /* Ensure it is behind the overlay content */
}

.overlay-content {
  position: absolute;
  top: 18px;
  left: 100px;
  transform: translate(-50%, -50%);
  z-index: 20;
  border-radius: 8px;
}
