.bp-input-numeric {
  &,
  &:focus,
  &:hover {
    @apply py-1.5 px-2 text-sm h-10 w-full border border-gray-700 rounded outline-none shadow-none placeholder-gray-200
    disabled:bg-gray-50 disabled:text-gray-400 ;
    min-width: 0;
  }

  .--has-error & {
    @apply border-red-500;
  }
}

.custom-numeric-input {
  @apply border-gray-300 text-base font-medium #{!important};
}
