.bp-select {

  .ant-select-dropdown {
    @apply w-max;
  }

  &.ant-select-show-arrow .ant-select-selector {
    @apply pr-8;
  }

  .ant-select-selector  {
    @apply h-10 border-gray-700 rounded #{!important};

    .--has-error & {
      @apply border-red-500 #{!important};
    }
  }

  .ant-select-selection-item {
    @apply flex items-center whitespace-pre #{!important};
  }

  .ant-select-selection-search {
    @apply flex items-center #{!important};
  }

  .ant-select-selection-placeholder {
    @apply py-1 #{!important};
  }

  .ant-select-item-option-content {
    @apply whitespace-pre;
  }

}