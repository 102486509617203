.ant-menu-item-selected {
  background-color: transparent !important;
  color: black;
}

.ant-menu-item:hover {
  color: black !important;
}

.ant-btn-primary {
  background-color: #1890ff;
}

.ant-picker-input {
  font-weight: 400;
  outline: none;
  color: gray;
}

.ant-picker-input > input::placeholder {
  font-weight: 400;
  color: gray;
}

.co-pro-add-form {
  .bp-input-numeric {
    border-color: theme("colors.gray.300");
  }
}
