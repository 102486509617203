.more-details-purchase-details thead tr th {
  font-weight: 300 !important;
  border-left: none !important;
  white-space: nowrap !important;
  border-bottom: 1px !important;
}

.more-details-purchase-details thead tr th:not(:nth-child(1)) {
  text-align: right !important;
}

.more-details-purchase-details tbody tr td:not(:nth-child(1)) {
  text-align: right !important;
}

.more-details-purchase-details tbody tr td {
  font-weight: 500 !important;
}

.more-details-purchase-details {
  table-layout: fixed;
  width: 100%;
}

.more-details-purchase-details thead tr th:not(:last-child),
.more-details-purchase-details tbody tr td:not(:last-child) {
  padding-right: 19px;
}
