.bp-input {
  &,
  &:focus,
  &:hover,
  &:disabled {
    @apply p-2 border-gray-700 rounded outline-none shadow-none;
    min-width: 0;
  }

  .--has-error & {
    @apply border-red-500;
  }
}
