.nav-standard {
  border-bottom: none;
}
.nav-standard .nav-link {
  @apply bg-transparent;
}

.modal-button-assigntoSale {
  background-color: #111111 !important;
  color: #ededed !important;
}
