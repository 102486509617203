.ant-table-container {
  border-width: 1px;
  border-radius: 0.25rem;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
  background-color: rgb(229 231 235);
}

#div-dashboard-table{

  // TODO: REMOVE STYLES
  // WE SHOULD NOT OVERWRITE COMMON COMPONENT STYLE, 
  // ITS UNDERSTABLE TO WRITE POSITIONING/MARGIN/PADDING OF THOSE

.ant-table-tbody > tr > td {
  border-bottom: 1px solid #d1cbcb;
  text-align: left;
  padding: 10px 10px;
}

.ant-table-thead > tr > th {
  border-bottom: 2px solid #d1cbcb;
  text-align: left;
  font-weight: bold;
  padding: 14px 10px;
  color: #161038;
}
}
.ant-table-tbody tr:last-child > td {
  border-bottom: 0px;
}

.ant-pagination-item-active {
  border: solid 2px #161038 !important;
}

.ant-pagination-item-active a {
  color: #161038;
}

.ant-table-pagination.ant-pagination {
  margin: 16px 16px;
}

.ant-pagination-prev,
.ant-pagination-next {
  display: none;
}

.ant-pagination-item {
  border: 0;
}

.ant-pagination-item a {
  font-weight: bold;
}

.ant-table-cell {
  color: rgb(75 85 99);
}

.ant-checkbox-wrapper {
  padding-right: 10px;
  padding-left: 10px;
}

.ant-table-wrapper {
  min-width: 100%;
  border: 1px solid rgb(209 213 219);
  border-radius: 0 0 0.5rem 0.5rem;
}
.ant-picker-separator {
  display:none;
}

.ant-picker-range-separator:after {
  content: "\2192" !important;
}

.ant-picker-range-separator {
  padding :0 1.5rem;
  font-size: 15px;
  color: rgb(75 85 99);
}

.ant-table-pagination li:last-child {   
   display: flex !important;
}

.masterNETSCO2Costs-form {
  @apply grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-x-4 gap-y-4;


  .bp-formfield {
    @apply w-full;
    min-width: 0
  }

  .bp-input {
    @apply w-full;
  } 
}

.masterNETSConversionFactorsForm-form {
  @apply grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-x-4 gap-y-4;


  .bp-formfield {
    @apply w-full;
    min-width: 0
  }

  .bp-input {
    @apply w-full;
  } 
}

.div-dashboard-table-long-text{  
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;  
  word-break: break-word;  
}

.div-summary-table{
  .ant-table-tbody > tr > td {
    padding :10px 10px;
  }
  .ant-table-thead > tr > th {
    padding :14px 10px;
  }
  .ant-table-tbody > tr {
    height:46px;
  }

  .ant-progress-inner{
    border-radius: 3px !important;
  }
  .ant-progress-bg{
    height: 24px !important;
    border-radius: 3px !important;
  }

  .ant-progress-show-info .ant-progress-outer{
    margin-right: 0px !important;
    padding-right: 0% !important;
  }
  .ant-progress-text{
    margin-left:0px !important;
    top: 22% !important;
    left: 5% !important;
    position: absolute !important;
    color:white !important;
    font-weight: 700 !important;
  }

}

.bp-green-gradient {
  background-image: linear-gradient(to right, #408b40 -1%, #49a147 62%, #c8d500 112%);
  // @apply pt-12 pr-8 pl-8;
}