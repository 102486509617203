.bp-menu {
  .menu-title {
    @apply pb-2 font-medium text-gray-700 text-sm;
  }
  // WITHIN DROPDOWN
  .ant-dropdown-menu-item {
    @apply text-base transition-none bg-transparent w-fit;
    min-width: 12rem;
  }
  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-item a {
    @apply font-bold bg-gray-50;
  }

  

  .ant-dropdown-menu,
  .ant-menu-submenu,
  .ant-menu {
    @apply text-base border-none bg-transparent;

    .ant-menu-submenu:hover .ant-menu-submenu-arrow,
    .ant-menu-submenu-selected {
      @apply text-inherit;
    }

    .ant-menu-item:hover,
    .ant-menu-submenu-title:hover,
    .ant-menu-submenu-title:hover .ant-menu-submenu-arrow,
    .ant-menu-title-content,
    .ant-menu-item-selected a,
    a:hover {
      @apply text-gray-700;
      text-decoration: none;
    }

    .ant-menu-item,
    .ant-menu-submenu-title {
      @apply h-5 mt-0 py-5 text-sm;

      &::after {
        @apply border-none;
      }
    }
  }

  .ant-menu-sub {
    @apply ml-9;
    .ant-menu-item {
      width: 200px;
      @apply ml-2 hover:bg-white rounded-sm;
    }
    .false {
      @apply hover:bg-transparent;
    }
  }

  .sub-menu-item {
    @apply relative;
    padding-left: 2.0em !important;
  }

  .sub-menu-item:before {
    content: "";
    @apply left-0 ml-3 bg-transparent w-2.5 h-2.5 absolute inline-block rounded-lg;
  } 

  .menu-item-background {
    @apply relative;
    padding-left: 1.0em !important;
  }

  .menu-item-background:before {
    content: "";
    display: inline-block;
    @apply left-0 w-1.5 h-11 items-center absolute inline-block ;
  }

  .hide-before:before {
    display: none;
  }

  .hide-before-menu:before {
    display: none;
  }

  .show-before-menu:before {
    display: inline-block;
  }

  .bg-bp-green-100-color:before{
    @apply  bg-bp-green-100;
  }

  .bg-blue-500-color:before{
    @apply  bg-blue-500;
  }

  .bg-selector7-color:before{
    @apply  bg-selector7;
  }

  .bg-red-500-color:before{
    @apply  bg-red-500;
  }

  .bg-yellow-400-color:before{
    @apply  bg-yellow-400;
  }
  .bg-bp-green-900-color:before{
    @apply  bg-bp-green-900;
  }

  .bg-selector1-color:before{
    @apply  bg-selector1;
  }
  

  .bg-selector1-bg, 
  .bg-bp-green-900-bg,
  .bg-selector3-bg,
  .bg-red-500-bg,
  .bg-bp-green-100-bg,
  .bg-blue-500-bg,
  .bg-selector7-bg{
    background-color: theme('colors.gray.100') !important
  }  


  .show-hover-menu:hover {
    background-color: theme('colors.gray.100') !important
  }
}
