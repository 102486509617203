.pos-uk-edit-form {
  .bp-input-numeric,
  .bp-input {
    border-color: theme("colors.gray.300");
  }
}

.click-away {
  z-index: 21;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.pos-container {
  .ant-dropdown,
  .ant-btn {
    z-index: 22;
  }
}

.rowC {
  display: flex;
  flex-direction: row;
}

div.horizontalgap {
  float: left;
  overflow: hidden;
  height: 1px;
  width: 0px;
}

.bp-card1 {
  @apply w-full p-5 border border-gray-200 rounded-md;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-template-rows: 100px 300px;
  grid-gap: 10px;
  background-color: #2196f3;
  padding: 10px;
}

.grid-container > div {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  padding: 20px 0;
  font-size: 30px;
}
