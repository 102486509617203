.incoming-docs-table {
  @apply min-h-[500px] w-full;
}

.incoming-docs-table tr th {
  @apply bg-transparent text-[#111];
}

.outgoing-docs-table {
  @apply min-h-[500px] w-full;
}

.outgoing-docs-table tr th {
  @apply bg-transparent text-[#111];
}

.incoming-docs-table + .react-table-pagination {
  padding-bottom: 9.5rem;
}
