.bp-textarea {

  textarea, textarea:focus, textarea:hover {
    @apply p-2 border border-gray-700 rounded outline-none shadow-none resize-none w-full;

    .--has-error & {
      @apply border-red-500;
    }

  }

  &.ant-input-textarea-show-count {
    // @apply mb-4;

    &::after {
      @apply text-xs;
    }

  }
}