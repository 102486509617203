$border-dark: #666666;
$border-bottom: #cccccc;
$border-light: #ccc;
$background-color: #f2f2f2;
$table-header-color: #ededed;
$light-background-color: #fafafa;
$dark-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
$transitionShadow: $dark-shadow 250ms;
$z-index-sticky: 100;
$dark-secondary: #232323;
$schedule-btn-color: #111111;
$blue-chip-bg: #ecfafe;
$blue-chip-border: #4fc3f7;
$green-chip-bg: #e6fff2;
$red-chip-bg: #ffedf0;
$red-chip-border: #fcb8c3;
$amber-chip-bg: #fffff1;
$green-chip-border: #2ee68c;
$darker-primary: #343434;
$light-secondary: #fafafa;
$border-gray: #dedede;
$success: green;
$bp-dark-green: #24aa54;

.valid-total-concentration {
  color: #3d8f36;
  padding-left: 10px;
}
.invalid-total-concentration {
  color: #aa4a44;
  padding-left: 10px;
}

.information-missing-text {
  color: #aa4a44;
  font-size: small;
  display: inline-block;
  margin-top: 8px;
}

#copro-container {
  background-color: $light-background-color;
  .co-processing-container {
    padding-top: 10px;
    font-weight: 300;
    color: #111;
  }

  .receipts-header {
    &__header {
      align-items: center;
      display: grid;
      grid-area: header;
      border: none;
      padding-bottom: 40px;
      -moz-transition: all 250ms;
      transition: all 250ms;
    }
    &__sticky-header {
      @apply bg-white;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      padding: 20px 40px;
      box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
      -moz-transition: $transitionShadow;
      transition: $transitionShadow;
      z-index: $z-index-sticky;
    }
    &__dateRange {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      padding: 0px 40px 0px 40px;
    }
    &__dateLabel {
      font-size: 1.25rem /* 20px */;
      line-height: 1;
      vertical-align: text-bottom;
      margin-right: auto;
    }
    &__datePicker {
      .flatpickr-wrapper {
        @apply bg-black;
        width: 320px;
        color: white;
      }
    }
    &__dateInput {
      height: 42px; // match schedule btn height
      padding-left: 40px;
      border-left: 0px;
      @apply text-white;
    }
    .section-header {
      padding: 0px 40px 0px 40px;
    }
    .text-xl {
      padding: 0px 40px 0px 40px;
    }
  }

  .copro-contract-summary {
    background-color: $background-color;
    padding: 0px 40px 0px 40px;
  }
  .receipts {
    padding: 20px 40px 160px 40px;
    background-color: $background-color;
    &__header {
      align-items: center;
      display: grid;
      grid-area: header;
      border: none;
      padding-bottom: 40px;
      -moz-transition: all 250ms;
      transition: all 250ms;
    }
  }
  .contract-summary-graph {
    @media only screen and (max-width: 1980px) {
      margin-right: 1.75rem;
      max-width: 1430px;
      width: 100%;
    }
  }
  .section-chip {
    width: fit-content;
    height: 27px;
    margin: 0 418px 11px 0;
    padding: 4px 10px;
    border-radius: 3px;
    background-color: $table-header-color;
  }
  .section-titles {
    font-size: 1.25rem /* 20px */;
    letter-spacing: 1.52;
    padding-bottom: 13px;
  }
  .copro-status-pills {
    padding: 0px 40px 0px 40px;
    .nav-tabs {
      background-color: $light-background-color;
      border-bottom: none;
    }
    .nav-link {
      &.active {
        background-color: $background-color;
      }
    }
  }
  .copro-table {
    background-color: #f2f2f2;

    thead {
      background-color: $table-header-color;
    }
    td,
    th {
      white-space: nowrap;
      font-size: 13px;
      min-width: 78px;
      padding-right: 15px;
      height: 52px;
    }
    td {
      border: solid 1px $border-light;
      padding: 15px;
    }
    th {
      text-align: left;
      background-color: $table-header-color;
      border-bottom-color: #ccc !important;
      border-top-color: #666666 !important;
    }

    .copro-table-header {
      height: 60px;
      padding: 10px 0 0;
      background-color: #fff;
      border-bottom: solid 1px $border-dark;
    }

    button {
      font-size: 14px;
      height: 40px;
    }

    .input-group {
      border: solid 1px $border-light;
      min-width: 255px;
    }
  }

  .batch-allocation-details-table {
    background-color: #f2f2f2;
    thead {
      background-color: $table-header-color;
    }
    td,
    th {
      white-space: nowrap;
      font-size: 13px;
      min-width: 78px;
      height: 52px;
    }
    td {
      border: solid 1px $border-light;
      padding: 15px;
    }
    th {
      text-align: left;
      background-color: $table-header-color;
      border-bottom-color: #ccc !important;
      border-top-color: #666666 !important;
    }
  }

  .batches-table-container,
  .shipments-table-container,
  .credit-gen-table-container {
    .batches-tab-row,
    .credit-gen-tab-row {
      background-color: $light-background-color;
      padding-left: 40px;
    }
    .nav-tabs {
      background-color: $light-background-color;
    }
    .nav-link {
      &.active {
        background-color: $background-color;
      }
    }

    .btn {
      svg {
        color: $dark-secondary;
      }
    }

    .batches-table {
      tr.nested {
        & > td {
          border: none;

          &:first-child {
            padding-left: 3rem;
          }
        }

        & + tr:not(.nested) {
          border-top: solid 1px #dee2e6;
        }
      }

      th {
        color: black;
        padding: 10px;
        min-width: 120px;
        background-color: $light-background-color;
        border-bottom: solid 1px $border-light;
      }
      th:first-child {
        margin-left: 10px;
      }
      th:not(:first-child) {
        text-align: right;
        div {
          justify-content: end;
          margin-right: 10px;
        }
      }

      td {
        white-space: nowrap;

        &:not(:first-child) {
          text-align: right;
        }
      }

      &.last-bordered tbody tr {
        &:nth-last-child(2),
        &:last-child {
          & > td {
            border-bottom: solid 1px;
          }
        }
      }
    }
  }

  .contract-summary-graph {
    background-color: #fff;
  }
  textarea {
    resize: none;
  }
}

#collapsible-card {
  .collapse {
    visibility: visible !important;
  }
}

.save-secondary-button {
  background-color: $dark-secondary;
  color: $table-header-color;
}

.schedule-btn {
  background-color: $schedule-btn-color;
  color: $table-header-color;
}

// Popovers
.tasks-popover {
  padding: 0;
  min-width: 340px;
}

.bulk-adjustment-batch-popover {
  min-width: 300px;
  .popover-body {
    color: $darker-primary;
  }
}

.schedule-popover {
  max-width: 170px;
  padding: 0;
  .popover-body {
    opacity: 1;
  }
}

.delete-popover {
  width: 330px !important;
  padding: 0 !important;
  .popover-body {
    opacity: 1;
  }
}

.copied-popover {
  padding: 6px 16px !important;
  .popover-body {
    opacity: 1;
  }
}

.batches-volume-bar {
  padding: 20px 10px 40px 10px;
  background-color: $light-secondary;
  border-radius: 12px;
  margin-bottom: 10px;

  .solid-fill {
    background-color: $bp-dark-green;
  }

  .striped-fill {
    background-color: transparent;
    background-image: repeating-linear-gradient(
      45deg,
      $bp-dark-green,
      $bp-dark-green 5px,
      transparent 5px,
      transparent 10px
    );
  }

  .volume-bar {
    width: 100%;
    background-color: $light-secondary;
    position: relative;

    &__total {
      display: flex;
      height: 20px;
      width: 100%;
      justify-content: space-between;
    }

    &__remaining {
      background-color: $bp-dark-green;
      height: 100%;
      margin-right: 5px;
    }

    &__ended {
      height: 100%;
      transition: width 0.5s ease;
    }

    &__volume {
      margin-right: 5px;
    }
  }

  .legend {
    color: #000;
    padding-top: 20px;
    font-size: 12px;
    white-space: nowrap;
  }
}

.copro-discard-chip,
.copro-batches-chip {
  border-radius: 3px;
  padding: 4px 16px;
}
.copro-net-change-chip {
  border-radius: 13.5px;
  padding: 4px 12px;
  background-color: $blue-chip-border;
}
.copro-blue-chip {
  background-color: $blue-chip-bg;
}
.copro-green-chip {
  background-color: $green-chip-bg;
}
.copro-red-chip {
  background-color: $red-chip-bg;
}
.copro-red-chip-border {
  border-color: $red-chip-border;
}
.copro-amber-chip {
  background-color: $amber-chip-bg;
}
