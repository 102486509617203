.route-details {
  .route-location-indicator {
    width: 2px;
    height: 21px;
    background-color: #007f00;
  }
  .route-location-connector {
    width: 100px;
    height: 2px;
    background-color: #007f00;
  }

  .route-mot {
    width: 38px;
    height: 38px;
    border-radius: 999px;
    background-color: #0079b9;
  }

  .route-mot-null {
    width: 38px;
    height: 38px;
    border-radius: 999px;
    background-color: #a1a1a1;
    align-items: center;
    justify-content: center;
  }
}
.mot-popup-warning-text {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 0.2px;
  color: #111;
}
